const DEV_ENDPOINT = `http://localhost:3000/graphql`;
const PROD_ENDPOINT = `https://api.staging.plataformaunidos.net/graphql`;

const DEV_CHAT_ENDPOINT = `http://localhost:3001`;
const DEV_CHAT_WS_ENDPOINT = `ws://localhost:3001`;

const PROD_CHAT_ENDPOINT = `https://chat.staging.plataformaunidos.net`;
const PROD_CHAT_WS_ENDPOINT = `wss://chat.staging.plataformaunidos.net`;

export const ENDPOINT =
  process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;

export const CHAT_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_ENDPOINT
    : PROD_CHAT_ENDPOINT;

export const CHAT_WS_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_WS_ENDPOINT
    : PROD_CHAT_WS_ENDPOINT;

export const PER_PAGE = 8;
